@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Manrope:300,400,500,600,700&display=swap&subset=latin-ext");
:root {
  --body-bg-color: #e5ecef;
  --theme-bg-color: #fff;
  --settings-icon-hover: #9fa7ac;
  --developer-color: #f9fafb;
  --input-bg: #f8f8fa;
  --input-chat-color: #a2a2a2;
  --border-color: #eef2f4;
  --body-font: "Manrope", sans-serif;
  --body-color: #273346;
  --settings-icon-color: #c1c7cd;
  --msg-message: #969eaa;
  --chat-text-bg: #f1f2f6;
  --theme-color: #0086ff;
  --msg-date: #c0c7d2;
  --button-bg-color: #f0f7ff;
  --button-color: var(--theme-color);
  --detail-font-color: #919ca2;
  --msg-hover-bg: rgba(238, 242, 244, 0.4);
  --active-conversation-bg: linear-gradient(
   to right,
   rgba(238, 242, 244, 0.4) 0%,
   rgba(238, 242, 244, 0) 100%
  );
  --overlay-bg: linear-gradient(
   to bottom,
   rgba(255, 255, 255, 0) 0%,
   rgba(255, 255, 255, 1) 65%,
   rgba(255, 255, 255, 1) 100%
  );
  --chat-header-bg: linear-gradient(
   to bottom,
   rgba(255, 255, 255, 1) 0%,
   rgba(255, 255, 255, 1) 78%,
   rgba(255, 255, 255, 0) 100%
  );
}

@tailwind base;
@tailwind components;
@tailwind utilities;

[data-theme=purple] {
  --theme-color: #9f7aea;
  --button-color: #9f7aea;
  --button-bg-color: rgba(159, 122, 234, 0.12);
}

[data-theme=green] {
  --theme-color: #38b2ac;
  --button-color: #38b2ac;
  --button-bg-color: rgba(56, 178, 171, 0.15);
}

[data-theme=orange] {
  --theme-color: #ed8936;
  --button-color: #ed8936;
  --button-bg-color: rgba(237, 137, 54, 0.12);
}

.dark-mode {
  --body-bg-color: #1d1d1d;
  --theme-bg-color: #27292d;
  --border-color: #323336;
  --body-color: #d1d1d2;
  --active-conversation-bg: linear-gradient(
   to right,
   rgba(47, 50, 56, 0.54),
   rgba(238, 242, 244, 0) 100%
  );
  --msg-hover-bg: rgba(47, 50, 56, 0.54);
  --chat-text-bg: #383b40;
  --chat-text-color: #b5b7ba;
  --msg-date: #626466;
  --msg-message: var(--msg-date);
  --overlay-bg: linear-gradient(
   to bottom,
   rgba(0, 0, 0, 0) 0%,
   #27292d 65%,
   #27292d 100%
  );
  --input-bg: #2f3236;
  --chat-header-bg: linear-gradient(
   to bottom,
   #27292d 0%,
   #27292d 78%,
   rgba(255, 255, 255, 0) 100%
  );
  --settings-icon-color: #7c7e80;
  --developer-color: var(--border-color);
  --button-bg-color: #393b40;
  --button-color: var(--body-color);
  --input-chat-color: #6f7073;
  --detail-font-color: var(--input-chat-color);
}

.blue {
  background-color: #0086ff;
}

.purple {
  background-color: #9f7aea;
}

.green {
  background-color: #38b2ac;
}

.orange {
  background-color: #ed8936;
}

* {
  margin: 0;
  outline: none;
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

body {
  background-color: var(--body-bg-color);
  font-family: var(--body-font);
  color: var(--body-color);
}

html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

.logo svg {
  width: 100%;
}

.dark-light {
  width: 22px;
  height: 22px;
  color: var(--settings-icon-color);
  flex-shrink: 0;
}
.dark-light svg {
  width: 100%;
  fill: transparent;
  transition: 0.5s;
}

.user-profile {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.add {
  position: sticky;
  bottom: 25px;
  background-color: var(--theme-color);
  width: 60px;
  height: 60px;
  border: 0;
  border-radius: 50%;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-plus'%3e%3cpath d='M12 5v14M5 12h14'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 28px;
  box-shadow: 0 0 16px var(--theme-color);
  margin: auto auto -55px;
  flex-shrink: 0;
  z-index: 1;
  cursor: pointer;
}



.detail-area-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.detail-area-header .msg-profile {
  margin-right: 0;
  width: 60px;
  height: 60px;
  margin-bottom: 15px;
}

.detail-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
}

.detail-subtitle {
  font-size: 12px;
  font-weight: 600;
  color: var(--msg-date);
}

.detail-button {
  border: 0;
  background-color: var(--button-bg-color);
  padding: 10px 14px;
  border-radius: 5px;
  color: var(--button-color);
  display: flex;
  align-items: center;
  /*justify-content: center; */
  font-size: 14px;
  flex-grow: 1;
  font-weight: 500;
}
.detail-button svg {
  width: 18px;
  margin-right: 10px;
}
.detail-button:last-child {
  margin-left: 8px;
}

.detail-buttons {
  margin-top: 20px;
  display: flex;
  width: 100%;
}

.detail-changes {
  margin-top: 40px;
}

.detail-change {
  color: var(--detail-font-color);
  font-family: var(--body-font);
  font-weight: 600;
  font-size: 14px;
  border-bottom: 1px solid var(--border-color);
  padding: 14px 0;
  display: flex;
}
.detail-change svg {
  width: 16px;
  margin-left: auto;
}

.colors {
  display: flex;
  margin-left: auto;
}

.color {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  cursor: pointer;
}
.color.selected {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' stroke='%23fff' stroke-width='3' fill='none' stroke-linecap='round' stroke-linejoin='round' class='css-i6dzq1' viewBox='0 0 24 24'%3E%3Cpath d='M20 6L9 17l-5-5'/%3E%3C/svg%3E");
  background-size: 10px;
  background-position: center;
  background-repeat: no-repeat;
}
.color:not(:last-child) {
  margin-right: 4px;
}

.detail-photo-title {
  display: flex;
  align-items: center;
}
.detail-photo-title svg {
  width: 16px;
}

.detail-photos {
  margin-top: 30px;
  text-align: center;
}

.detail-photo-title {
  color: var(--detail-font-color);
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 20px;
}

.view-more {
  color: var(--theme-color);
  font-weight: 600;
  font-size: 15px;
  margin: 25px 0;
}

.developer {
  position: absolute;
  color: var(--detail-font-color);
  font-weight: 600;
  left: 0;
  top: -100%;
  display: flex;
  transition: 0.3s;
  padding: 0 20px;
  align-items: center;
  justify-content: center;
  background-color: var(--developer-color);
  width: 100%;
  height: 100%;
}

.developer img {
  border-radius: 50%;
  width: 26px;
  height: 26px;
  object-fit: cover;
  margin-right: 10px;
}

.dark-mode .search-bar input,
.dark-mode .detail-area input {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 56.966 56.966' fill='%236f7073'%3e%3cpath d='M55.146 51.887L41.588 37.786A22.926 22.926 0 0046.984 23c0-12.682-10.318-23-23-23s-23 10.318-23 23 10.318 23 23 23c4.761 0 9.298-1.436 13.177-4.162l13.661 14.208c.571.593 1.339.92 2.162.92.779 0 1.518-.297 2.079-.837a3.004 3.004 0 00.083-4.242zM23.984 6c9.374 0 17 7.626 17 17s-7.626 17-17 17-17-7.626-17-17 7.626-17 17-17z'/%3e%3c/svg%3e");
}
.dark-mode .dark-light svg {
  fill: #ffce45;
  stroke: #ffce45;
}
.dark-mode .chat-area-group span {
  color: #d1d1d2;
}

.chat-area-group {
  flex-shrink: 0;
  display: flex;
}
.chat-area-group * {
  border: 2px solid var(--theme-bg-color);
}
.chat-area-group * + * {
  margin-left: -5px;
}
.chat-area-group span {
  width: 32px;
  height: 32px;
  background-color: var(--button-bg-color);
  color: var(--theme-color);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
}

@media (max-width: 1120px) {
  .detail-area {
    display: none;
  }
}
@media (max-width: 780px) {
  .conversation-area {
    display: none;
  }

  .search-bar {
    margin-left: 0;
    flex-grow: 1;
  }
  .search-bar input {
    padding-right: 10px;
  }
}