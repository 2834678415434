html{
  font-size: 16px;
}
@media (max-width: 1000px) {
  html{
    font-size: 12px;
  }
  .preview-template{
    width: 50%;
  }
  .self-start{
    width: 50%;
  }
  
}
@media (max-height: 700px) {
  .modlBtn{
    padding: 0 0.5rem;
  }
}
.wrapper {
  width: 100%;
  display: flex;
  flex-grow: 1;
  overflow: hidden;
}

.app {
  display: flex;
  flex-direction: column;
  background-color: var(--theme-bg-color);
  max-width: 1600px;
  height: 100vh;
  margin: 0 auto;
  overflow: hidden;
}

.preview-template {
  height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.bgImg {
  background-image: url('./assets/bg.jpg');
  max-height: 25rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 30px 10px;
    height: 80%;
    overflow-y: auto;
    overflow-x: hidden;
}
.chat-bubbles{
  background: #dbffcf;
    /* border-radius: 10px 10px 0px 10px; */
    border-radius: 10px;
    width: 80%;
    padding: 10px;
    margin-right: 12px;
    position: relative;
    font-size: small;
    -webkit-box-shadow: 15px 21px 36px -7px rgba(0,0,0,0.1);
-moz-box-shadow: 15px 21px 36px -7px rgba(0,0,0,0.1);
box-shadow: 15px 21px 36px -7px rgba(0,0,0,0.1);
}

.bubble-arrow {
  position: absolute;
  width: 0;
  /* bottom: 42px; */
  right: 0;
  /* left: 204px; */
  height: 0;
  bottom: 27px;
}
.bubble-arrow:after {
  content: "";
  position: absolute;
  border: 0 solid transparent;
  border-top: 9px solid #dbffcf;
  border-radius: 0 20px 0;
  width: 15px;
  height: 30px;
  transform: rotate(45deg) scaleY(-1);
}
input[type="file"]{
  padding:10px;
}
.circle_cont{
  position: fixed;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: -webkit-fill-available;
    background: #ffffff52;
}
.svg {
  width: 100px;
  overflow: visible;
}
.svg circle {
  stroke: #3b82f6;
  transform-origin: 50px 50px;
  animation: spin 1.2s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
.sample-image, .sample-file{
  height: 150px;
    background: #e9e9e9;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.sample-image svg, .sample-file svg{
  color: grey;
}
