.msg {
  display: flex;
  align-items: center;
  padding: 20px;
  cursor: pointer;
  transition: 0.2s;
  position: relative;
}

.msg:hover {
  background-color: var(--msg-hover-bg);
}

.msg.active {
  background: var(--active-conversation-bg);
  border-left: 4px solid var(--theme-color);
}
.msg.online:before {
  content: "";
  position: absolute;
  background-color: #23be7e;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  border: 2px solid var(--theme-bg-color);
  left: 50px;
  bottom: 19px;
}

.msg-profile {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 15px;
}
.msg-profile.group {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--border-color);
}
.msg-profile.group svg {
  width: 60%;
}

.msg-username {
  margin-bottom: 4px;
  font-weight: 600;
  font-size: 15px;
}

.msg-detail {
  overflow: hidden;
}

.msg-content {
  font-weight: 500;
  font-size: 13px;
  display: flex;
}

.msg-message {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--msg-message);
}


.msg-date {
  font-size: 14px;
  color: var(--msg-date);
  margin-left: 3px;
}
.msg-date:before {
  content: "•";
  margin-right: 2px;
}